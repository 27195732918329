body {
  font-family: 'Arial', sans-serif;
}

h2,
h6 {
  font-weight: 800 !important;
  letter-spacing: -0.5px;
}

h2 {
  font-size: 26px !important;
}

h5 {
  font-weight: 600 !important;
}

h6 {
  font-size: 20px !important;
}

strong {
  font-weight: 800;
}

h6#react-admin-title {
  font-size: 18px !important;
}

.MuiCardContent-root,
.RaTabbedShowLayout-content {
  padding: 32px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiStepper-root .MuiStep-root .MuiStepLabel-root:first-child {
  margin-left: -10px;
}

.step2-instructions-box p button {
  font-size: 1rem;
  font-weight: 600;
  margin-top: -3px;
}

.add-horizontal-padding {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.buttons-container button {
  padding: 11px 45px;
  box-shadow: none;
  border-radius: 0;
}

.buttons-container.rollback-btn button {
  color: #000;
}

.buttons-container.rollback-btn button:hover {
  background-color: #cdcdcd;
}

.previews a {
  color: rgb(24, 119, 177);
}

.success-message {
  border: 1px solid #187630;
  color: #187630 !important;
}

table.operations-list {
  table-layout: fixed !important;
}

table.operations-list tr th:nth-child(2) {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 300px;
  white-space: nowrap;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.formiterator .RaSimpleFormIterator-indexContainer {
  display: none !important;
}

.formiterator ul,
.formiterator .MuiFormControl-root {
  margin: 0;
}

.formiterator .RaSimpleFormIterator-line {
  border: none !important;
}

.formiterator .RaSimpleFormIterator-form {
  flex-direction: row !important;
  gap: 10px;
  justify-content: space-between;
}

#main-content {
  padding: 50px !important;
}

.SnackbarContainer-top {
  top: 60px !important;
}

.ra-field-shortDescription p,
.summary-label p,
.ra-field-inputFile p,
.ra-field-status p {
  font-weight: 600;
  color: #000;
}

td.y {
  background-color: palegreen;
  text-align: center;
}

td.n {
  background-color: lightcoral;
  text-align: center;
}